.product-action {

  &--lg {
    .btn-white {
      @extend .btn-lg;
    }
  }

  &__button {

  }

}