.benefits {

  &__item {
    display: table;
    font-size: .9rem;
  }

  &__ico {
    display: table-cell;
    vertical-align: middle;
    color: $benefits-ico-color;
    padding-right: 0.7em;
  }

  &__img{
    padding: 15px;
    border: 1px solid $base-border-color;
  }

  &__inner {
    display: table-cell;
    vertical-align: middle;
  }

  &__title {
    margin-bottom: 0.2em;
    color: $benefits-title-color;
    font-size: 1.2em;
    line-height: 1;
    font-weight: bold;
  }

  &__desc {
    color: $benefits-desc-color;
  }
}