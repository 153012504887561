.product-purchase {
  $indent: 1em;

  &__flex-container {
    display:     flex;
    align-items: center;
    margin-top:  - $indent;
    margin-left: - $indent;

    & > * {
      margin-top:  $indent;
      margin-left: $indent;
    }
  }
  &__item {

  }

  &__quantity {
    width: 100px;
  }
}