.compare{

	&__product{
		height: $compare-product-height;
		overflow: hidden;
		padding: 0 10px;
	}

	&__params{
		font-size: 0.9rem;
		color: #000;

		&-item{			
			padding: 2px 10px;
			height: 40px;
			overflow: hidden;

			&:nth-child(even){
				background: $compare-product-bg;
				border: 1px dashed darken($compare-product-bg, 5%);
				border-right: 0;
				border-left: 0;
			}

			&--title{
				font-weight: bold;
			}
		}

		
		&-value{
			display: table;
			width: 100%;
			height: 100%;

			&:before{
				content: "";
				width: 0;
				height: 100%;
				display: table-cell;
				vertical-align: middle;
			}
		}

		&-text{
			display: table-cell;
			width: 100%;
			vertical-align: middle;
		}
	}

	&__delete{
		padding-top: 3px;
		
		&-link{
			font-size: 0.9em;
    	color: $base-font-color-secondary;
      text-decoration: underline;

      &:hover{
        text-decoration: none;
      }
		}
	}

}








// .compare{

// 	&__products{
// 		height: $compare-product-height;
// 		overflow: hidden;

// 	}

// 	&__params{
// 		font-size: 0.9rem;
// 		color: #000;

// 		&-item{
// 			display: table;
// 			width: 100%;
// 			padding: 0 15px;
// 			height: 40px;
// 			overflow: hidden;

// 			&:nth-child(even){
// 				background: $compare-product-bg;
// 				border: 1px solid darken($compare-product-bg, 5%);
// 				border-right: 0;
// 				border-left: 0;
// 			}

// 			&:before{
// 				content: "";
// 				height: 100%;
// 				display: inline-block;
// 				vertical-align: middle;
// 			}

// 			&--title{
// 				font-weight: bold;
// 			}			

// 		}

// 		&-text{
// 			display: inline-block;
// 			vertical-align: middle;				
// 		}

// 	}
// }