.paginator {
  $size: rem(48);
  $size-xs: rem(35);
  display:         flex;
  justify-content: center;
  align-items:     flex-start;
  flex-wrap:       wrap;

  &__item {
    position:      relative;
    height:        $size-xs;
    width:         $size-xs;
    border:        1px solid $base-border-color;
    border-radius: $btn-border-radius-base;
    margin:        2px;
    font-weight:   bold;
    text-align:    center;
    white-space:   nowrap;
    overflow:      hidden;

    &:not(&--active):not(&--empty):hover {
      background-color: $theme-frame-color;
    }

    a, span {
      @include absolute-center-margin;
      display:         flex;
      justify-content: center;
      align-items:     center;
      color:           inherit;
      text-decoration: none;
      &:hover {
        color:           inherit;
        text-decoration: none;
      }
    }

    &--active {
      background-color: $base-font-color;
      border-color:     $base-font-color;
      color:            #FFF;
    }
    &--empty { border-color: transparent; }

    &--first { }
    &--last { }

    &--next,
    &--prev {
      display: none;
    }
    &--next {
      order:       1;
      margin-left: 1.5em;
    }
    &--prev {
      order:        -1;
      margin-right: 1.5em;
    }
  }

  &__arrow {
    display: block;
    height:  1em;
    width:   1em;
  }

  @media (min-width: $screen-sm-min) {
    flex-wrap: nowrap;

    &__item {
      height: $size;
      width:  $size;

      &--prev,
      &--next { display: block; }
    }
  }
}