/*
--------------------------------------------------
Navigation main-navs styles
--------------------------------------------------
*/
.main-nav {
  $z-intdex: $z-index-s;
  position: relative;

  &__item {
    position: relative;
    display: block;
    background-color: $main-nav-bg-color;
    border: 1px solid $main-nav-border-color;
    border-top: 0;

    &.is-active{
      background-color: $main-nav-bg-active-color;
    }

    &:hover {
      background: $main-nav-bg-hover-color;
      color: $main-nav-link-hover-color;
    }

  }


  &__link {
    display: block;
    padding: 10px 20px;
    color: $main-nav-link-color;
    text-decoration: none;
  }

  &__arrow {
    position: absolute;
    right: 5px;
    width: 7px;
    height: 7px;
    color: $main-nav-arrow-color;
  }


  &__drop {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 100%;

    visibility: hidden;
    opacity: 0;
    height:     1px;
    overflow:   hidden;

    //Element Modifiers
    &[data-nav-direction="rtl"] {
      right: 0;
      left: auto;
    }
  }

  &__item:hover > &__drop {
    visibility: visible;
    opacity: 1;
    height:     auto;
    overflow:   visible;
  }

}