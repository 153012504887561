.widget-secondary {
  $arrow-height: 60px;
  $arrow-width: 27px;
  $arrow-bg: #f8f8f8;

  &__header {
    margin-bottom: 20px;
    font-size: 0;

    &--border {
      padding-bottom: 10px;
      border-bottom: 1px solid $base-border-color;
    }
  }

  &__title {
    display: inline-block;
    vertical-align: baseline;
    font-size: 1.5rem;
    line-height: 1;
    color: $widget-secondary-title-color;
  }

  &__viewall {
    display: inline-block;
    vertical-align: baseline;
    font-size: .9rem;

    &:before {
      content: '/';
      padding: 0 10px;
    }
  }

  &__hlink {
    @include link-main;
  }

  &__inner {
    position: relative;
  }

  &__list {
    margin-bottom: -25px;
  }

  &__item {
    margin-bottom: 25px;

    &--border-vertical {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px dashed $base-border-color;
    }
  }

  &__footer {
    font-size: .9rem;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $arrow-width;
    height: $arrow-height;
    background-color: $arrow-bg;
    border: 1px solid $base-border-color;
    text-align: center;
    cursor: pointer;

    &:not(.slick-disabled):hover {
      background-color: darken($arrow-bg, 2%);
    }

    &--left {
      left: 0;
    }
    &--left-out {
      left: -10px;
    }
    &--right {
      right: 0;
    }
    &--right-out {
      right: -10px;
    }

    /* styles for disabled arrow, when slick carousel infinite is disabled */
    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__svg {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 30px;
    color: #000;
  }

}