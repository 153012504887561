.tree-nav {
  position: relative;
  text-align: left;
  background: $tree-nav-bg-color;

  &__item {
    position: relative;
    border: 1px solid $main-nav-border-color;
    border-top: 0;

    &.is-active{
      background-color: $main-nav-bg-active-color;
    }

    &:hover {
      background: $tree-nav-link-hover-bg;
      color: $tree-nav-link-hover-color;
    }

    &--top-drop {
      position: static;
    }
  }

  &__link {
    display: block;
    white-space: nowrap;
    position: relative;
    padding: 10px 30px 10px 10px;
    color: $tree-nav-link-color;
    text-decoration: none;
  }

  &__ico {
    float: left;
    margin-top: -1px;
    margin-right: 4px;
  }

  &__arrow {
    position: absolute;
    right: 5px;
    width: 7px;
    height: 7px;
    color: $main-nav-arrow-color;
  }

  &__drop {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 100%;

    visibility: hidden;
    opacity: 0;
    height:     1px;
    overflow:   hidden;

    //Element Modifiers
    &[data-nav-direction="rtl"] {
      right: 0;
      left: auto;
    }
  }

  &__item:hover > &__drop {
    visibility: visible;
    opacity: 1;
    height:     auto;
    overflow:   visible;
  }

}