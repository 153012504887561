.product-buy {

  &__available {
    @include clearfix;
    /* compensate quantity and button extra vertical indention */
    margin-top: -10px;
  }

  &__quantity {
    float: left;
    margin-right: 10px;
    /* extra indent to make vertical space on mobile devices when button transit into another row */
    margin-top: 10px;
    width: 140px;
  }

  &__buttons {
    float: left;
    /* extra indent to make vertical space on mobile devices when button transit into another row */
    margin-top: 10px;
  }

  &__btn {
    @extend .btn;
    &--buy {
      @extend .btn-primary;
    }
    &--in-cart {
      @extend .btn-default;
    }
  }
  &--product &__btn {
    @extend .btn-lg;
  }
  &--product &__btn--in-cart {
    @extend .btn-light;
  }

  &__unavailable {
  }
  &__unavailable-info {
    font-size: 0.9em;
    color: #ff4a4a;

    &::before {
      content: 'x';
      font-size: 1.2rem;
    }
  }
  &__unavailable-notify {
  }
  &__unavailable-link {
    @include link-main;
    @include link-js;
    font-size: 0.9em;
  }

}