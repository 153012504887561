.mfp-bg {
  background: $page-overlay-color;
  opacity:    1;
}
.modal {
  $footer-indent: 1em;
  $close-icon-size: 24px;
  position:   relative;
  margin:     0 auto;
  width:      90%;
  max-width:  $modal-size;
  background: #FFF;

  /* Modal modifiers */
  &--sm {
    max-width: $modal-size-sm;
  }

  &--lg {
    max-width: $modal-size-lg;
  }

  /* Modal elements */
  &__header {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    padding:         20px $modal-hor-padding;
    background:      #FFF;
    border-bottom:   1px solid $modal-header-border;

    &-title {
      font-size:     rem(22);
      line-height:   1;
      font-weight:   bold;
      color:         $modal-header-color;
      padding-right: 20px;
      overflow:      hidden;
      text-overflow: ellipsis;
      white-space:   nowrap;
    }

    &-close {
      flex-shrink: 0;
      width:       $close-icon-size;
      height:      $close-icon-size;
      color:       $base-font-color-strong;
      cursor: pointer;

      &:hover {
        color: $theme-secondary-color;
      }
      &:active {
        color: $theme-main-color;
      }
    }

  }

  &__content {
    padding: 15px $modal-hor-padding;

    &-cell {
      margin-bottom: 15px;
    }
  }

  &__footer {
    padding:    25px $modal-hor-padding 15px;

    &-row {
      display:         flex;
      justify-content: flex-end;
      margin-top:      - $footer-indent;
      margin-left:     - $footer-indent;
      text-align: right;

      &:not(:first-child){
        padding-top: 15px;
      }

      & > * {
        margin-top:  $footer-indent;
        margin-left: $footer-indent;
      }

      @media (min-width: $screen-sm-min) {
        justify-content: space-between;
      }
    }
  }

}