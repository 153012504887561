.catalog-toolbar {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      padding-left: 50px;
    }
  }

  &__label {
    padding-right: 10px;
    white-space: nowrap;
  }

  &__field {
  }

  &__control {
  }
}