.seo-text {
  padding: 35px 20px;
  background: $theme-frame-color;

  &__title {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: $seo-text-title-color;
  }

  &__desc {

  }

}