.content {
  padding-top:    25px;
  padding-bottom: 45px;

  // layouts
  &__container {
    // extending bootstrap container
    // using when full width frames inside container are needed and there are no page__container active!
    @extend .container-fluid;
    max-width: $site-max-width;
  }
  &__flex-container {
    display:         flex;
    flex-direction:  column;
    justify-content: flex-start;
    align-items:     flex-start;
  }
  &__sidebar {
    width:       100%;
    flex-shrink: 0;

    &-item {
      padding-bottom: 40px;
    }
  }
  &__body {
    flex-basis: calc(100% - #{$page-sidebar-width + $page-sidebar-indent});
    flex-grow:  1;
  }
  @media (min-width: $screen-sm-min) {
    &__flex-container {
      flex-direction: row;
    }
    &__sidebar {
      width:        $page-sidebar-width;
      margin-right: $page-sidebar-indent;
    }
  }

  // ----
  &__main-banner {
    padding-bottom: 20px;
  }

  &__header {
    display:         flex;
    flex-direction:  column;
    flex-wrap:       wrap;
    justify-content: space-between;
    margin-bottom:   10px;

    @media (min-width: $screen-lg) {
      flex-direction: row;
      margin-bottom:  30px;
      border-bottom:  2px solid $theme-main-color;
    }
  }
  &__header-item {
    padding-bottom: 20px;

    @media (min-width: $screen-lg) {
      padding-bottom: 10px;

      &:first-child {
        padding-right: 15px;
      }
    }
  }

  &__title {
    display:        inline-block;
    padding-bottom: 8px;
    padding-right:  6px;
    font-size:      2rem;
    line-height:    1.1;
    color:          #000;
  }

  &__quote {
    color: $base-font-color-secondary;
  }

  &__hinfo {
    display:      inline-block;
    font-size:    0.9em;
    color:        $base-font-color-secondary;
    white-space:  nowrap;

    padding-left: 0.7em;
    border-left:  1px solid $base-border-color;

    &-number {
      font-weight: bold;
    }
  }

  /* Content universal wrapper for vertical space */
  &__row {
    padding-bottom: 45px;

    &:last-child {
      padding-bottom: 0;
    }

    &--sm {
      padding-bottom: 20px;
    }

    &--lg {
      padding-bottom: 65px;
    }
  }

  /* Content pagination wrapper */
  &__pagination {
    margin-top: 25px;
    text-align: center;
  }

  /* Content toolbar wrapper */
  &__toolbar {
    padding-bottom: 20px;
  }
}