.catalog-btn{

  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0 20px;
  background: $theme-main-color;
  color: #fff;
  z-index: $z-index-drop + 1;
  cursor: pointer;

  &__hamburger{
    width: 14px;
    height: 12px;
    flex-shrink: 0;
    margin-top: -2px; 
  }

  &__arrow{
    width: 11px;
    height: 11px;
    flex-shrink: 0;
    margin-top: -2px;
  }

  &__label{
    flex-grow: 1;
    padding: 0 12px;
  }

  &__drop{
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
  }

  &__drop {
    &.is-hidden {
      visibility: hidden;
      opacity:    0;
    }
  }
}