/*
--------------------------------------------------
Navigation table-navs styles
--------------------------------------------------
*/

.cols-nav {

  &__row {
    display: inline-flex;
    border: 1px solid $cols-nav-border-color;
    border-left: 0;
    text-align: left;
    background: $cols-nav-bg-color;
    font-size: 1rem;
    padding: 5px;
  }
  & [data-megamenu-wrap="true"] {
    flex-wrap: wrap;
  }

  & [data-megamenu-wrap="false"] {
    flex-wrap: nowrap;
  }

  &__col {
    width: $cols-subnav-col-width;
    padding: 5px;
  }

  &__item {
    line-height: 1;
    margin-bottom: 10px;
  }

  &__link {
    @include link-second;    
  }

}

.cols-subnav {
  margin-top: 3px;

  &__item {
    padding-top: 5px;

    &--level-2 {
      font-size: .9em;

      &::before {
        content: '-';
      }
    }
  }

  &__link {
    @include link-main;
    line-height: 1;
    font-size: .9em;
  }
  &__item--level-2 &__link {
    color: #666;

    &:hover {
      text-decoration: underline;
    }
  }
}