.order-details {

  background: $theme-frame-color;
  padding: 30px 40px;
  color: #000;

  &__group {
    display: table;
    width: 100%;
    table-layout: fixed;
    &:not(:last-child) {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px dashed darken($theme-frame-color, 15%);
    }
  }

  &__list {
    display: table-row;
  }

  &__item {
    display: table-cell;
    vertical-align: top;
    padding-bottom: 10px;

    &:not(:first-child) {
      padding-left: 30px;
    }

    &--title {
      font-weight: bold;
    }

  }
}