.small-post {
  display: flex;
  flex-wrap: wrap;
  font-size: .9rem;

  &__image {
    width: 35%;
    padding-right: 10px;
  }

  &__inner {
    width: 65%;
  }

  &__desc {
    width: 100%;
    padding-top: 5px;
  }

  &__date {
    display: block;
    color: $base-font-color-secondary;
    padding-bottom: 3px;
  }

  &__title {
    font-size: 1.1em;
  }

}