.product-thumb{
  display: flex;

  &__photo{
    flex-shrink: 0;
    margin-right: 10px;
    width: 60px;
    height: 60px;
    border: 1px solid $base-border-color;
    padding: 5px;
  }

  &__img{
    max-height: 100%;
  }

  &__title{
    font-size: 0.9rem;
  }

  &__price{
    padding-top: 5px;
  }

}