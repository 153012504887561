.catalog-map {

  &__item {
    display: block;
    text-align: center;
  }

  &__imagebox {
    height: 162px;
    margin-bottom: 10px;
    position: relative;
  }

  &__img {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100%;
    margin: auto;
  }

  &__title {
    font-size: .9rem;
  }

}